/* eslint-disable import/no-extraneous-dependencies */
import Model from '@tripian/model';
import data from './data';

const dataClear = () => {
  // data.register = undefined;
  // data.token = undefined;
  data.user = undefined;

  data.favorites = {} as Record<Model.LangCodeKey, { cityId: number; favoritePois: Model.Favorite[] }[]>;
  data.companions = {} as Record<Model.LangCodeKey, Model.Companion[]>;
  // data.notifications = undefined;
  data.reservations = {} as Record<Model.LangCodeKey, { cityId: number; userReservations: Model.UserReservation[] }[]>;
  data.userReactions = {} as Record<Model.LangCodeKey, { tripHash: string; userReactions: Model.UserReaction[] }[]>;

  data.tripRefs = undefined;
  data.tripSavedRefs = undefined;
  data.trips = {} as Record<Model.LangCodeKey, Model.Trip[]>;
  data.trip = {} as Record<Model.LangCodeKey, Model.Trip>;
  data.tripSavedRefs = undefined;
  // data.recommendations = undefined;
};

export default dataClear;
