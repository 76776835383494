/* eslint-disable react/require-default-props */

import React, { useMemo, useState } from "react";
import moment from "moment";
import Model from "@tripian/model";

import Gmap from "../../components/Gmap/GMap";
import MapSearch from "./MapSearch/MapSearch";
import CurrentLocation from "./CurrentLocation/CurrentLocation";
import ShowAllAlternatives from "./ShowAllAlternatives/ShowAllAlternatives";

import ShowSearchOffers from "./ShowSearchOffers/ShowSearchOffers";
import ShowAccommodations from "./ShowAccommodations/ShowAccommodations";
import ShowCarRentOffers from "./ShowCarRentOffers/ShowCarRentOffers";
// import useLayoutPlan from "../../hooks/useLayoutPlan";
import PlanRoute from "./PlanRoute/PlanRoute";
import useTrip from "../../../../hooks/useTrip";
import usePlan from "../../../../hooks/usePlan";
import { MapCategory } from "@tripian/react";
import classes from "./MapLayout.module.scss";

interface IMapLayout {
  tripReference: Model.TripReference;
  planDayIndex: number;
  fullWidth: boolean;
  alternativesStepId?: number;
  poiCategoryGroups: Model.PoiCategoryGroup[];
  offersSearchVisible: boolean;
  setOffersSearchVisible: (newVisible: boolean) => void;
  loadingSearchOffers: boolean;
  offersResult: Model.Poi[];
  searchOffer: () => Promise<Model.Poi[]>;
  selectedPoiCategoryIds: number[];
  setSelectedPoiCategoryIds: (selectedCategoryIds: number[]) => void;
  sharedTrip?: boolean;
  t: (value: Model.TranslationKey) => string;
}

const MapLayout: React.FC<IMapLayout> = ({
  tripReference,
  planDayIndex,
  fullWidth,
  alternativesStepId,
  poiCategoryGroups,
  offersSearchVisible,
  setOffersSearchVisible,
  loadingSearchOffers,
  offersResult,
  searchOffer,
  selectedPoiCategoryIds,
  setSelectedPoiCategoryIds,
  sharedTrip = false,
  t,
}) => {
  // const day = useSelector((state: ICombinedState) => state.trip.day);
  const { tripReadOnly } = useTrip();
  const { plans } = usePlan();

  const [showAllalternatives, setShowAllalternatives] = useState<boolean>(false);
  const [searchThisAreaPois, setSearchThisAreaPois] = useState<Model.Poi[]>([]);
  // const { offersSearchVisible, setOffersSearchVisible } = useLayoutPlan();
  const [showAccommodations, setShowAccommodations] = useState<boolean>(false);
  const [showCarRentOffers, setShowCarRentOffers] = useState<boolean>(false);

  // useEffect(() => {
  //   setShowAllalternatives(false);
  // }, [day]);

  const mapClasses = [classes.mapContainer];
  if (!window.tconfig.SHOW_OVERVIEW) mapClasses.push("container-height");
  else mapClasses.push("container-height-tab");
  if (fullWidth) mapClasses.push(classes.wm100);

  const googleMap = useMemo(
    () => (
      <Gmap
        planDayIndex={planDayIndex}
        showAllalternatives={showAllalternatives}
        alternativesStepId={alternativesStepId}
        searchThisAreaPois={searchThisAreaPois}
        showAccommodations={showAccommodations}
        showCarRentOffers={showCarRentOffers}
        cycling={tripReference.tripProfile.answers.includes(407)}
        hideRoutes={sharedTrip && window.tconfig.WIDGET_THEME_1}
        selectedPoiCategoryIds={selectedPoiCategoryIds}
      />
    ),
    [
      alternativesStepId,
      planDayIndex,
      searchThisAreaPois,
      sharedTrip,
      showAccommodations,
      showAllalternatives,
      showCarRentOffers,
      tripReference.tripProfile.answers,
      selectedPoiCategoryIds,
    ]
  );
  const showShowAccommodations = useMemo(() => {
    const startTripDatetime = moment(tripReference.tripProfile.arrivalDatetime).format("X");
    const datetimeNow = moment(new Date()).format("X");
    return window.tconfig.SHOW_ACCOMMODATION_POIS && datetimeNow < startTripDatetime;
  }, [tripReference]);

  const showShowCarRentOffes = useMemo(() => {
    const startTripDatetime = moment(tripReference.tripProfile.arrivalDatetime).format("X");
    const datetimeNow = moment(new Date()).format("X");
    return window.tconfig.SHOW_CAR_RENT_POIS && datetimeNow < startTripDatetime;
  }, [tripReference]);

  return (
    <div className={mapClasses.join(" ")}>
      {googleMap}
      <CurrentLocation />
      {sharedTrip && window.tconfig.WIDGET_THEME_1 && plans ? (
        <>
          <div className={classes.mapCategories}>
            <MapCategory
              categoryGroups={poiCategoryGroups.filter((poiCategory) =>
                plans[0].steps.some((step) => step.poi.category.some((category) => poiCategory.ids.some((categoryId) => categoryId === category.id)))
              )}
              selectedPoiCategoryIds={selectedPoiCategoryIds}
              setSelectedPoiCategoryIds={(newIndex: number[]) => setSelectedPoiCategoryIds(newIndex)}
              clearCategories={() => setSelectedPoiCategoryIds([])}
              t={t}
            />
          </div>
          <div className={classes.mapLeftTopContainer}>
            {window.tconfig.SHOW_OFFERS && (
              <ShowSearchOffers
                show={offersSearchVisible}
                setShow={setOffersSearchVisible}
                setSearchThisAreaPois={setSearchThisAreaPois}
                loadingSearchOffers={loadingSearchOffers}
                offersResult={offersResult}
                searchOffer={searchOffer}
              />
            )}
          </div>
        </>
      ) : (
        <>
          <MapSearch show setSearchThisAreaPois={setSearchThisAreaPois} poiCategoryGroups={poiCategoryGroups} />

          <ShowAllAlternatives show={showAllalternatives} setShow={setShowAllalternatives} />
          <div className={classes.mapLeftTopContainer}>
            {window.tconfig.SHOW_OFFERS && tripReadOnly === false && (
              <ShowSearchOffers
                show={offersSearchVisible}
                setShow={setOffersSearchVisible}
                setSearchThisAreaPois={setSearchThisAreaPois}
                loadingSearchOffers={loadingSearchOffers}
                offersResult={offersResult}
                searchOffer={searchOffer}
              />
            )}
            {showShowAccommodations && <ShowAccommodations show={showAccommodations} setShow={setShowAccommodations} />}
            {showShowCarRentOffes && <ShowCarRentOffers show={showCarRentOffers} setShow={setShowCarRentOffers} />}
          </div>
        </>
      )}
      {plans && <PlanRoute planId={plans[planDayIndex].id} tripHash={tripReference.tripHash} />}
    </div>
  );
};

export default MapLayout;
